import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FacebookIcon, WhatsappIcon, TwitterIcon } from "react-share"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import { Main, Container } from "../components/UI"

const WaitListPage = props => {
  const user = props.location.state?.user || null
  const canonicalUrl = `https://thetoucan.app/waitlist/`
  return (
    <Layout>
      <Seo
        title="You're on the waitlist! - Toucan"
        noIndex={true}
        canonicalUrl={canonicalUrl}
      />
      <div className="bg-white">
        <Main className="overflow-hidden pt-20 pb-44">
          <Container className="text-center font-spartan text-gray-2">
            <div className="relative mb-20 h-[300px] overflow-hidden rounded-3xl shadow-2xl ">
              <StaticImage
                src="https://www.datocms-assets.com/54391/1637844636-jungle1.jpg"
                alt="Join Waitlist Toucan"
                className="h-full w-full"
              />
              <div className="absolute inset-0 flex items-end justify-center bg-gradient-to-b from-transparent to-black">
                <h1 className="mb-6 text-5xl font-semibold text-white">
                  Welcome to the jungle.
                </h1>
              </div>
            </div>
            <p className="mb-2">
              We have added you to the waitlist — you are currently:
            </p>
            <span className="mb-10 block text-black">
              <strong>#{user?.list_position}</strong>
            </span>
            <hr className="mb-10" />
            <h2 className="mb-5 text-3xl font-bold">
              Win £1000 for your charity
            </h2>
            <p className="mb-3">
              If 5 of your friends join the waitlist, you'll enter a draw to win
              £1000 for your chosen charity.
            </p>
            <p className="mb-3">
              Share your unique like:{" "}
              <a
                href={user?.referral_link}
                target="_blank"
                rel="noreferrer"
                className="font-bold"
              >
                {user?.referral_link}
              </a>
            </p>
            <div className="mb-10">
              <p className="mb-4 text-sm">Or share via:</p>
              <div>
                <a
                  href={`https://www.facebook.com/sharer.php?u=${user?.referral_link}`}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-10 inline-flex items-center overflow-hidden rounded-md bg-[#3b5998] font-spartan font-bold text-white"
                >
                  <FacebookIcon size={40} />
                  <span className="mr-[15px] inline-block">Share</span>
                </a>
                <a
                  href={`https://i.prefinery.com/projects/bszgr5yp/share/redirect/whatsapp?message=Join%20me%20on%20the%20Toucan%20waitlist%21%20-%20${user?.referral_link}%26utm_source%3Dwhatsapp%26utm_medium%3Dreferral`}
                  target="_blank"
                  rel="noreferrer"
                  className="mr-10 inline-flex items-center overflow-hidden rounded-md bg-[#25d366] font-spartan font-bold text-white"
                >
                  <WhatsappIcon size={40} className="ml-1" />
                  <span className="mr-[15px] inline-block">Send</span>
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?url=${user?.referral_link}&text=Join%20me%20on%20the%20Toucan%20waitlist%21`}
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex items-center overflow-hidden rounded-md bg-[#00aced] font-spartan font-bold text-white"
                >
                  <TwitterIcon size={40} />
                  <span className="mr-[15px] inline-block">Tweet</span>
                </a>
              </div>
            </div>
            <hr className="mb-10" />
            <div className="">
              <h2 className="mb-5 text-2xl font-bold">
                So far you have {user?.referrals} shares and {user?.referrals}{" "}
                referrals
              </h2>
              <p>
                Every time a friend clicks your link and signs up, you get
                closer to entering the draw.
              </p>
            </div>
          </Container>
        </Main>
      </div>
    </Layout>
  )
}

export default WaitListPage
